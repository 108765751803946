import React from "react";
import { Card as CardComponent } from "theme-ui";
import { Layout, Stack, Main, Sidebar } from "@layout";
import CardList from "@components/CardList";
import Divider from "@components/Divider";
import Sticky from "@components/Sticky";
import Seo from "@widgets/Seo";
import AuthorCompact from "@widgets/AuthorCompact";
import Categories from "@widgets/Categories";
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostCommentsFacebook,
  PostCommentsGraph,
  PostTagsShare,
  PostFooter,
} from "@widgets/Post";
import { useBlogCategories } from "@helpers-blog";
import TableOfContentsCompact from "@widgets/TableOfContentsCompact/TableOfContentsCompact";
import TableOfContentsExpanded from "@widgets/TableOfContentsExpanded/TableOfContentsExpanded";

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : []),
  ];
  const { pageContext: { services = {}, siteUrl } = {} } = props;
  const categories = useBlogCategories();

  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: "fadeInDown" }}>
        <PostHead {...post} />
      </Stack>
      <Divider />
      <Stack direction="column" effectProps={{ fraction: 0 }}>
        {post.tableOfContents?.items && (
          <>
            <TableOfContentsExpanded {...post} />
            <Divider />
          </>
        )}
        <Main>
          <CardComponent variant="paper">
            <PostImage {...post} inCard />
            <PostBody {...post} />
            <PostTagsShare {...post} location={props.location} />
            {services.disqus && <PostComments {...post} />}
            {services.graphComment && <PostCommentsGraph {...post} />}
            {services.facebookComment && (
              <PostCommentsFacebook {...post} siteUrl={siteUrl} />
            )}
            <PostFooter {...{ previous, next }} />
          </CardComponent>
        </Main>
        <Sidebar>
          <AuthorCompact author={post.author} omitTitle />
          <Divider />
          <Categories categories={categories} />
          <Divider />
          {post.category && (
            <Sticky>
              <CardList
                title="Related Posts"
                nodes={relatedPosts}
                variant="horizontal-aside"
                omitMedia
                omitCategory
                limit={6}
                distinct
                aside
              />
            </Sticky>
          )}
        </Sidebar>
      </Stack>
    </Layout>
  );
};

export default Post;
